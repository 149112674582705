<template>
  <div id="Questionnaire-rgbd" class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-overflow-y-auto tw-w-full tw-max-w-80 tw-py-6">
    <template v-if="loading_qst">
        <content-placeholders class="">
          <content-placeholders-text :lines="10" />
        </content-placeholders>
    </template>
    <template v-else>
      <img width="6%" :src="referer.logo" alt="LOGO" />

      <h3 class="tw-font-semibold tw-text-2xl tw-w-fit tw-mt-8" style="letter-spacing: -0.5px;">Questionnaire RGPD</h3>
        
      <span class="mon-entreprise-para tw-mt-3">Répondez à toutes les questions pour établir votre score.</span>
    
      <div class="tw-w-full marginTop tw-max-w-[60rem] mt-12" id="table">
        <div class="tw-font-semibold tw-mb-4 tw-text-sm">
          Lorsque vous répondrez par « Non » vous bénéficierez d'éclairages
          <svg class="tw-inline-block tw-ml-1" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8.62C16 11.2 14.73 13.185 12.798 14.492C12.348 14.796 12.123 14.948 12.012 15.122C11.902 15.294 11.863 15.522 11.788 15.976L11.728 16.329C11.596 17.127 11.529 17.526 11.249 17.763C10.969 18 10.565 18 9.756 18H7.144C6.335 18 5.931 18 5.651 17.763C5.371 17.526 5.305 17.127 5.171 16.329L5.113 15.976C5.037 15.523 5 15.296 4.89 15.124C4.78 14.952 4.554 14.798 4.103 14.49C2.192 13.183 1 11.199 1 8.62C1 4.413 4.358 1 8.5 1C9.00387 0.999471 9.50648 1.0504 10 1.152" stroke="#6C5DD3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.5 18V19C10.5 19.943 10.5 20.414 10.207 20.707C9.914 21 9.443 21 8.5 21C7.557 21 7.086 21 6.793 20.707C6.5 20.414 6.5 19.943 6.5 19V18M13.5 1L13.758 1.697C14.096 2.611 14.265 3.068 14.598 3.401C14.932 3.735 15.389 3.904 16.303 4.242L17 4.5L16.303 4.758C15.389 5.096 14.932 5.265 14.599 5.598C14.265 5.932 14.096 6.389 13.758 7.303L13.5 8L13.242 7.303C12.904 6.389 12.735 5.932 12.402 5.599C12.068 5.265 11.611 5.096 10.697 4.758L10 4.5L10.697 4.242C11.611 3.904 12.068 3.735 12.401 3.402C12.735 3.068 12.904 2.611 13.242 1.697L13.5 1Z" stroke="#6C5DD3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        
        <ChapterComponent
          v-for="(chapter, key) in blueprint"
          :key="key"
          :chapter="chapter"
          :chapter-order-number="key+1"
          :saved-chapter="savedChapters[key]"
          :can-show-questions="selectedChapterId == chapter.id"
          hide-recommendations
          @input-changed="checkIsCompleted"
          @selectChapter="selectChapterHandler"
        />
      </div>

      <div class="tw-flex tw-items-center tw-justify-center tw-space-x-20 tw-mt-8">
        <v-btn elevation="1" text rounded large :disabled="!isCompleted" @click="openPopup = true">Valider</v-btn>
      </div>
    </template>

    <AccepterPopup :openPopup="openPopup" @fetchData="valider" @close="openPopup = false" />
  </div>
</template>

<script>
import ChapterComponent from "@/features/company/components/Questionnaire/RGPDChapterComponent";
import AccepterPopup from "@/features/company/components/Questionnaire/AccepterPopup";
import { getPublicQuiBlueprint } from "@/features/company/services/appApi";
import { mapGetters } from "vuex";

export default {
  name: "GDPRPublicQuiz",
  components: {
    ChapterComponent,
    AccepterPopup,
  },
  data() {
    return {
      blueprint: [],
      chapters: [],
      savedChapters: [],
      loading_qst: true,
      selectedChapterId: 0,
      isCompleted: false,
      openPopup: false,
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    ...mapGetters(["getPublicQuizChapters"]),
  },
  mounted() {
    getPublicQuiBlueprint().then((response) => {
      this.blueprint = response.data.data;
      this.chapters = this.blueprint.map((item) => {
        return {
          chapterId: item.id,
          questions: item.questions.map((q) => {
            return {
              questionId: q.id,
              responses: q.responses.map((r) => {
                return {
                  id: r.id,
                  label: r.label,
                  responseId: null,
                };
              }),
            };
          }),
        };
      });
    })
    .catch((err) => {
      this.$snackbar.showAlertMessage({
        message: err.response.data.message,
        type: "error",
      });
    })
    .finally(() => {
      this.loading_qst = false;
    });
    
    // window.addEventListener('keydown', (e) => {
    //   if (e.key === 'f') this.fillAll();
    // });

    setTimeout(() => {
      this.savedChapters = this.getPublicQuizChapters;
      if (this.savedChapters.length > 0) this.chapters = this.savedChapters;
    }, 2000);
  },
  methods: {
    fillAll() {
      this.chapters = this.blueprint.map((item) => {
        return {
          chapterId: item.id,
          questions: item.questions.map((q) => {
            return {
              questionId: q.id,
              responses: q.responses.map((r, index) => {
                return {
                  id: r.id,
                  label: r.label,
                  responseId: index === 0 ? r.id : null,
                };
              }),
            };
          }),
        };
      });
      this.savedChapters = this.chapters;
      this.isCompleted = true;
    },
    selectChapterHandler(chapterId) {
      this.selectedChapterId = this.selectedChapterId == chapterId ? null : chapterId; 
    },
    valider() {
      this.$store.commit("SET_PUBLIC_QUIZ_CHAPTERS", this.chapters);
      this.$router.push({ name: "GdprPublicQuiz.signup" });
    },
    checkIsCompleted(chapterId, questions) {
      this.chapters = this.chapters.map((item) => {
        if (item.chapterId == chapterId) {
          return {
            chapterId,
            questions,
          };
        }
        return item;
      });

      const answeredChaptersCount = this.chapters.filter(
        (m) => m.questions.every(q => q.responses.some(r => !!r.responseId))
      ).length;

      this.isCompleted = answeredChaptersCount == this.blueprint.length;
    },
  }
};
</script>
<template>
  <div id="Questionnaire-container">
    <EntrepriseHeaderBlockTextComponent
      class="tw-w-full"
      :title="`Évaluation de la conformité RGPD de ${socialReason}`"
      description="Votre score et le détail de votre évaluation, les recommandations pour consolider ou faire progresser votre conformité"
    />
    <template v-if="loading_qst">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-grid tw-grid-cols-5 tw-gap-4 tw-w-full" v-if="showQuestion">
        <div class="tw-col-span-5 lg:tw-col-span-2 box-questionnaire">
          <span class="score" style="margin-top: 0;" :class="getColorBadge()">
            {{ total !== null ? total.toFixed() : 0 }} / 100
          </span>

          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-center tw-mx-auto tw-w-full">
            <div class="tw-w-full">
              <div 
                class="tw-flex tw-items-center tw-justify-around tw-font-semibold tw-text-white tw-text-sm tw-rounded-lg"
                :style="{ backgroundColor: `${theme.ctaBgColor} !important` }"
                style="padding: 2px 4px;"
              >
                <span>0</span>
                <span>20</span>
                <span>40</span>
                <span>60</span>
                <span>80</span>
                <span>100</span>
              </div>
              <div style="width: 82%; margin-left: 10px;">
                <svg class="tw-mt-1" :class="getColorBadge()" :style="{ marginLeft: `${total}%` }" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19" /></svg>
              </div>
            </div>

            <span class="tw-text-sm tw-font-bold tw-mt-2" :class="getColorBadge()">{{ scoreMessage.message }}</span>

            <p class="tw-text-sm tw-text-center" style="margin-top: .25rem; line-height: 1rem;">
              {{ scoreMessage.description }}
            </p>
          </div>

          <hr />
          <v-btn
              elevation="1"
              text
              rounded
              large
              @click="MiseAjour()"
              class="tw-text-white victor-submit-button tw-m-auto"
          >
            Mettre à jour
          </v-btn>
          <p>
            Du changement concernant votre conformité ?
            Une fois par an, vous avez la possibilité de modifier vos réponses
            au questionnaire et faire évoluer votre score.
          </p>

          <div 
            style="border: 2px solid #BB454C; border-radius: 10px; padding: 13px 15px 20px 15px; margin-top: 20px;"
            class="tw-flex tw-flex-col tw-items-center"
          >
            <span style="color: #BB454C;" class="tw-font-semibold">RECOMMANDATION</span>
            <span class="tw-mt-1 tw-text-xs tw-text-center tw-font-semibold">4 actions simples et immédiates pour améliorer votre score de conformité.</span>
            <ul class="tw-mt-4">
              <li class="tw-text-xs tw-pl-4 tw-flex" style="margin-bottom: 4px;"><b style="padding-right: 5px;">1 ></b> <span style="width: 90%;">automatisez l’évaluation de vos sous-traitants</span></li>
              <li class="tw-text-xs tw-pl-4 tw-flex" style="margin-bottom: 4px;"><b style="padding-right: 5px;">2 ></b> <span style="width: 90%;">faites la cartographie de votre écosystème</span></li>
              <li class="tw-text-xs tw-pl-4 tw-flex" style="margin-bottom: 4px;"><b style="padding-right: 5px;">3 ></b> <span style="width: 90%;">formez ou sensibilisez vos collaborateurs</span></li>
              <li class="tw-text-xs tw-pl-4 tw-flex" style="margin-bottom: 4px;"><b style="padding-right: 5px;">4 ></b> <span style="width: 90%;">mettez à jour votre registre de traitements</span></li>
            </ul>
          </div>

          <div 
            v-if="referrer.slug === 'gpmse'"
            style="border: 2px solid #0FB900; border-radius: 10px; padding: 13px 15px 20px 15px; margin-top: 20px;"
            class="tw-flex tw-flex-col tw-items-center"
          >
            <span style="color: #0FB900;" class="tw-font-semibold">CONSEIL</span>
            <span class="tw-mt-1 tw-text-xs tw-text-center" style="line-height: 1.5;">
              Pour effectuer et automatiser ces actions
              en quelques clics, souscrivez un abonnement à Viqtor® à partir de 26€HT/mois sans engagement.
            </span>
            <span class="tw-mt-4 tw-text-xs tw-text-center tw-font-bold">Pour votre abonnement, c’est ICI</span>
            <span class="tw-mt-4 tw-text-xs tw-text-center" style="line-height: 1.5;">
              Pour être accompagné ou pour une démo,
              prenez contact avec nous, c’est ICI
            </span>
          </div>
        </div>

        
        <ChaptersScoresList 
          class="tw-col-span-5 lg:tw-col-span-3 box-score"
          style="height: fit-content;"
          :table_data="table_data"
          :total="total"
          :chapter10="chapter10"
          :score-total="scoreTotal"
          @voirDetail="voirDetail" 
        />
      </div>
      <EmptyComponent v-else />
    </template>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import EmptyComponent from "@/features/company/components/Questionnaire/EmptyComponent";
import ChaptersScoresList from "@/features/company/components/Questionnaire/ChaptersScoresList";
import { getQuiz } from "@/features/company/services/appApi";

export default {
  name: "MonEntrepriseQuestionnairePage",

  components: {
    EntrepriseHeaderBlockTextComponent,
    EmptyComponent,
    ChaptersScoresList,
  },
  data() {
    return {
      loading_qst: false,
      table_data: [],
      score: null,
      scoreTotal: null,
      showQuestion: false,
      scoreMessages: [
        {
          message: "C’est un excellent score !",
          description: `
            Vous êtes dans une excellente dynamique de conformité,
            continuez à maintenir ces bonnes pratiques et
            renouvelez l’exercice régulièrement pour rester en tête.
          `,
          condition: (score) => score >= 85,
        },
        {
          message: "C’est un bon score !",
          description: `
            Quelques améliorations mineures, et davantage de bonnes pratiques,
            vous permettraient d’atteindre une conformité encore plus forte.
            Continuez sur cette voie pour progresser !
          `,
          condition: (score) => score >= 70 && score < 85
        },
        {
          message: "C’est un score encourageant !",
          description: `
            Des améliorations sont nécessaires pour garantir
            une meilleure conformité. En travaillant sur
            quelques points spécifiques, vous pourrez rapidement progresser.
          `,
          condition: (score) => score >= 55 && score < 70
        },
        {
          message: "C’est un score honorable !",
          description: `
            Ce résultat montre que des efforts doivent être faits pour assurer
            une conformité adéquate. Il est essentiel de revoir certaines pratiques
            et de mettre en place des mesures correctives.
          `,
          condition: (score) => score >= 40 && score < 55
        },
        {
          message: "C’est un score frustrant !",
          description: `
            Ce score indique que des failles existent en 
            termes de conformité Il est impératif 
            d’entreprendre des actions pour rectifier le tir.
          `,
          condition: (score) => score < 40
        }
      ],
      chapter10: [],
    };
  },
  computed: {
    scoreMessage() {
      return this.scoreMessages.find((message) => message.condition(this.total));
    },
    referrer() {
      return this.$store.state.referrer;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
    socialReason() {
      return this.$store.state.user.workInCompany.socialReason;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading_qst = true;
      getQuiz()
        .then((res) => {
          this.table_data   = res.data.data.quiz.quizChapters.sort((a, b) => a.chapterId - b.chapterId);
          this.scoreTotal   = res.data.data.quiz.status;
          this.showQuestion = !res.data.data.quiz.isDraft;
          this.total = res.data.data.quiz.scorePercent;

          this.chapter10 = this.table_data.find(chapter => chapter.chapterId === 10).quizQuestions.map((q) => ({
            label: q.question.label,
            responses: q.responses.map(r => r.label)
          }));
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_qst = false;
        });
    },
    getBadge(score) {
      if (score =="A") return "victor-badge-green";
      else if (score =="B") return "victor-badge-yellow";
      else return "victor-badge-red";
    },
    getLgBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-lg-badge-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-lg-badge-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-lg-badge-yellow";
      else return "victor-lg-badge-red";
    },
    getColorBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-yellow";
      else return "victor-red";
    },
    voirDetail() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Questionnaire.reponses",
      });
    },
    MiseAjour() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Questionnaire.RGBD",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#Questionnaire-container {
  .slide-fade-enter-active {
    animation: slide-fade .8s;
  }

  .slide-fade-leave-active {
    display: none;
  }

  @keyframes slide-fade {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }

    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
</style>

<template>
  <v-dialog v-model="open" transition="dialog-bottom-transition" persistent width="35%" content-class="rounded-xl">
    <div class="tw-py-8 tw-px-9">
      <h3 class="tw-font-semibold tw-text-2xl tw-text-center">Mon évaluation</h3>
      <p class="tw-mt-5 tw-font-medium tw-text-xs tw-w-4/5 tw-text-center tw-mx-auto">Visualisez votre score de conformité au RGPD</p>
    </div>

    <div class="tw-flex tw-justify-center tw-gap-x-12 tw-w-full tw-py-6">
      <v-btn width="160px" outlined @click="$emit('close')" elevation="0" style="border-radius: 10px;" :color="theme.ctaBgColor"><span class="tw-text-xs">Fermer</span></v-btn>
      <v-btn width="160px" @click="$emit('redirect')" elevation="0" style="border-radius: 10px;" :color="theme.ctaBgColor"><span class="tw-text-xs tw-text-white">Voir le détail</span></v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'DashboardRedirectionPopup',
  props: {
    canOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  watch: {
    canOpen: { handler(val) { (this.open = val) }, immediate: true },
  },
}
</script>
const tips = [
  {
    id: '1.1',
    isYesCase: false,
    isNoCase: true,
    illumination: "Un Comité de Pilotage pour le RGPD est un groupe de personnes désignées dans l’organisation pour superviser la mise en conformité et le respect des règles relatives à la protection des données. Il veille à ce que les actions nécessaires soient mises en place, coordonne les différents départements, et garantit que l’organisation reste à jour sur les obligations légales. Si vous êtes une petite structure, ce rôle peut être confié à une personne ou à un prestataire externe.",
    recommandationTitle: "Désignez un comité ou une personne pour superviser votre conformité au RGPD.",
    recommandationDetails: "Identifier les besoins spécifiques de votre organisation en matière de protection des données. Désigner une personne ou un groupe en interne ou externe pour former ce comité. Prévoir des réunions régulières pour assurer le suivi des actions de conformité. Documenter et officialiser le rôle et les responsabilités de ce comité.",
  },
  {
    id: '1.2',
    isYesCase: false,
    isNoCase: true,
    illumination: "Une politique générale de protection des données est un document simple et clair qui explique comment votre organisation collecte, utilise, stocke et protège les données personnelles. Ce document doit être compréhensible par vos clients et accessible sur votre site web ou par tout autre moyen.",
    recommandationTitle: "Rédigez une politique de protection des données et rendez-la accessible à vos clients.",
    recommandationDetails: "Rédiger un document expliquant votre approche de la gestion des données personnelles. Inclure des informations telles que : pourquoi vous collectez des données, comment elles sont utilisées, vos mesures de sécurité et les droits des personnes. Valider ce document avec un expert en conformité RGPD si nécessaire. Rendre ce document facilement accessible, par exemple sur votre site internet."
  },
  {
    id: '1.3',
    isYesCase: false,
    isNoCase: true,
    illumination: "Le Privacy by Design consiste à intégrer la protection des données personnelles dès la phase de conception de vos services, produits ou outils. Par exemple, limiter la collecte des données au strict nécessaire, anonymiser les informations sensibles, et prévoir des mécanismes pour que les utilisateurs puissent exercer leurs droits.",
    recommandationTitle: "Intégrez des mesures de protection des données dès la conception de vos services.",
    recommandationDetails: "Analyser vos services pour identifier les points où les données personnelles sont collectées ou utilisées. Réduire au maximum la collecte et le traitement des données (principe de minimisation). Mettre en place des outils ou processus pour protéger les données dès le départ (ex. anonymisation, chiffrement). Former les équipes à intégrer le respect de la vie privée dans tous les projets."
  },
  {
    id: '1.4',
    isYesCase: false,
    isNoCase: true,
    illumination: "Un programme de sensibilisation au RGPD est une formation régulière destinée à vos collaborateurs pour leur apprendre les bonnes pratiques liées à la protection des données (ex. ne pas partager des mots de passe, éviter les fuites d'informations, sécuriser les accès). Cela garantit que chacun comprend son rôle dans la conformité.",
    recommandationTitle: "Mettez en place un programme de sensibilisation au RGPD pour vos collaborateurs.",
    recommandationDetails: "Identifier les besoins de formation de vos collaborateurs en fonction de leurs rôles. Mettre en place des sessions de formation en interne ou via un prestataire spécialisé. Fournir des supports simples (guides, fiches pratiques) pour les aider à appliquer les bonnes pratiques au quotidien. Réaliser des rappels réguliers pour actualiser les connaissances."
  },
  {
    id: '2.1',
    isYesCase: false,
    isNoCase: false,
    illumination: "illumination",
    recommandationTitle: "recommandationTitle",
    recommandationDetails: "recommandationDetails"
  },
  {
    id: '2.2',
    isYesCase: false,
    isNoCase: false,
    illumination: "illumination",
    recommandationTitle: "recommandationTitle",
    recommandationDetails: "recommandationDetails"
  },
  {
    id: '2.3',
    isYesCase: false,
    isNoCase: false,
    illumination: "illumination",
    recommandationTitle: "recommandationTitle",
    recommandationDetails: "recommandationDetails"
  },
  {
    id: '2.4',
    isYesCase: false,
    isNoCase: true,
    illumination: "Le DPO est un expert chargé de veiller à la conformité au RGPD au sein de votre organisation. Il est le point de contact principal pour les autorités et les individus concernant la protection des données. Si vous n’êtes pas obligé de nommer un DPO, il est quand même conseillé d'avoir une personne référente.",
    recommandationTitle: "Désignez un DPO ou une personne référente pour la protection des données.",
    recommandationDetails: "Évaluez si la nomination d’un DPO est obligatoire pour votre structure. Si ce n’est pas obligatoire, désignez une personne référente pour la protection des données. Assurez-vous que cette personne soit formée au RGPD. Enregistrez la nomination du DPO auprès de la CNIL (ou équivalent dans votre pays, si nécessaire)."
  },
  {
    id: '2.5',
    isYesCase: false,
    isNoCase: true,
    illumination: "Un correspondant à la protection des données (ou un référent RGPD) est une personne ou une équipe qui aide à gérer les obligations liées à la protection des données. Ce rôle peut être interne ou externe, selon les besoins et les ressources disponibles.",
    recommandationTitle: "Identifiez un correspondant interne ou externe pour la gestion des données.",
    recommandationDetails: "Désignez une personne (interne ou externe) pour remplir le rôle de correspondant. Formez cette personne pour qu'elle connaisse les bases du RGPD et les obligations de l'organisation. Assurez-vous que cette personne soit impliquée dans les processus liés aux données (nouveaux projets, gestion des incidents, etc.)."
  },
  {
    id: '3.1',
    isYesCase: false,
    isNoCase: true,
    illumination: "Une procédure de violation de données est un document décrivant les étapes à suivre en cas de fuite, perte ou accès non autorisé à des données personnelles. Cette procédure doit inclure : l’identification de la violation, sa résolution, la notification aux autorités compétentes et, si nécessaire, aux personnes concernées.",
    recommandationTitle: "Créez une procédure claire pour gérer les violations de données.",
    recommandationDetails: "Rédigez une procédure claire pour gérer les violations de données, y compris les délais et responsabilités. Formez vos équipes à reconnaître et signaler rapidement une violation. Testez régulièrement la mise en œuvre de cette procédure pour identifier des améliorations. Préparez des modèles pour notifier la CNIL et les personnes concernées si nécessaire."
  },
  {
    id: '3.2',
    isYesCase: false,
    isNoCase: true,
    illumination: "Une cellule de crise regroupe les personnes clés pour gérer efficacement une violation de données. Elle peut inclure des responsables informatiques, juridiques, communication et sécurité. Cela garantit une réponse coordonnée et rapide.",
    recommandationTitle: "Constituez une cellule de crise pour réagir efficacement aux incidents.",
    recommandationDetails: "Identifiez les personnes essentielles qui composeront cette cellule. Définissez les rôles et responsabilités de chacun en cas de crise. Organisez des simulations pour évaluer leur préparation et affiner les processus. Documentez les réunions et actions entreprises pour chaque incident."
  },
  {
    id: '3.3',
    isYesCase: false,
    isNoCase: true,
    illumination: "Un plan de continuité d'activité (PCA) permet de continuer vos opérations essentielles après un incident majeur (cyberattaque, panne, catastrophe). Il inclut des solutions de sauvegarde des données, des accès alternatifs aux systèmes critiques et des procédures pour restaurer rapidement les activités.",
    recommandationTitle: "Développez un plan de continuité pour maintenir vos activités après un incident.",
    recommandationDetails: "Identifiez les activités critiques de votre organisation. Mettez en place un plan décrivant les étapes pour restaurer ces activités. Testez régulièrement ce plan pour vous assurer de son efficacité. Formez vos collaborateurs à leur rôle en cas de déclenchement du PCA."
  },
  {
    id: '3.4',
    isYesCase: false,
    isNoCase: true,
    illumination: "Les tests réguliers (comme des audits, tests d’intrusion ou simulations) permettent d’identifier et de corriger les failles de sécurité avant qu’elles ne soient exploitées. Cela fait partie des bonnes pratiques en matière de sécurisation des données.",
    recommandationTitle: "Testez régulièrement la sécurité de votre infrastructure informatique.",
    recommandationDetails: "Planifiez des audits de sécurité périodiques avec des experts internes ou externes. Réalisez des tests d’intrusion pour évaluer la robustesse de vos systèmes. Analysez les résultats et appliquez les correctifs nécessaires. Mettez en place un calendrier pour effectuer ces tests régulièrement."
  },
  {
    id: '4.1',
    isYesCase: false,
    isNoCase: true,
    illumination: "Allouer un budget spécifique à la sécurisation des données est essentiel pour garantir la mise en place des bonnes pratiques : logiciels de protection, formations, audits, et gestion des incidents. Cela montre également que l’organisation prend la protection des données au sérieux.",
    recommandationTitle: "Allouez un budget spécifique à la sécurisation des données.",
    recommandationDetails: "Évaluez les besoins financiers pour sécuriser vos données (logiciels, consultants, formation). Proposez ce budget lors de la planification financière annuelle. Priorisez les investissements en fonction des risques identifiés (ex. mise à jour de systèmes critiques). Suivez et ajustez ce budget régulièrement en fonction de vos besoins."
  },
  {
    id: '4.2',
    isYesCase: false,
    isNoCase: true,
    illumination: "Un RSSI (Responsable de la Sécurité des Systèmes d'Information) ou un prestataire externe est crucial pour identifier et gérer les menaces liées à la sécurité informatique et à la protection des données. Ils assurent également la mise à jour des solutions de sécurité.",
    recommandationTitle: "Engagez un RSSI ou un prestataire spécialisé en sécurité des données.",
    recommandationDetails: "Évaluez si un expert interne ou externe est nécessaire selon vos besoins et vos ressources. Recrutez ou engagez un partenaire spécialisé en sécurité des données. Assurez-vous qu’il ait une connaissance approfondie des exigences RGPD. Prévoyez des réunions régulières pour faire le point sur les enjeux et les solutions mises en œuvre."
  },
  {
    id: '5.1',
    isYesCase: false,
    isNoCase: true,
    illumination: "Le registre des traitements est un document obligatoire qui recense toutes les activités de traitement des données personnelles de votre organisation. Il permet de démontrer votre conformité au RGPD et constitue un outil de suivi efficace.",
    recommandationTitle: "Créez et complétez un registre des traitements de données.",
    recommandationDetails: "Identifiez toutes les activités qui impliquent des données personnelles dans votre organisation. Documentez pour chaque activité : la finalité, les données collectées, les acteurs impliqués, les bases légales, les mesures de sécurité, etc. Mettez à jour ce registre régulièrement. Utilisez des modèles ou outils fournis par la CNIL pour structurer ce registre."
  },
  {
    id: '5.2',
    isYesCase: false,
    isNoCase: true,
    illumination: "Toutes les données n’ont pas le même niveau de sensibilité. Par exemple, les informations de santé nécessitent une sécurité renforcée. Une analyse de la criticité des données vous permet d’adopter des mesures adaptées pour chaque type de données.",
    recommandationTitle: "Adaptez les niveaux de sécurité des données en fonction de leur criticité.",
    recommandationDetails: "Classez les données selon leur sensibilité (basse, moyenne, élevée). Mettez en place des mesures de protection adaptées (chiffrement, accès restreint, etc.). Documentez ces niveaux de sécurité dans vos procédures internes. Formez vos collaborateurs à appliquer ces mesures."
  },
  {
    id: '5.3',
    isYesCase: false,
    isNoCase: true,
    illumination: "L’approche basée sur les risques consiste à identifier les menaces et vulnérabilités liées à vos données, puis à mettre en place des mesures proportionnées pour les réduire. C’est une méthode proactive et efficace.",
    recommandationTitle: "Évaluez les risques et appliquez des mesures de sécurité proportionnées.",
    recommandationDetails: "Réalisez une analyse des risques liés à vos activités de traitement des données. Identifiez les menaces potentielles (ex. cyberattaques, accès non autorisés). Implémentez des solutions adaptées pour réduire ces risques (contrôles d’accès, sauvegardes). Révisez cette analyse régulièrement pour l’ajuster à de nouveaux enjeux."
  },
  {
    id: '5.4',
    isYesCase: false,
    isNoCase: true,
    illumination: "Un contrôle d'accès garantit que seules les personnes autorisées peuvent accéder aux données nécessaires pour leur travail. Cela limite les risques de fuites ou d'accès abusif et protège les données sensibles.",
    recommandationTitle: "Mettez en place des contrôles d’accès adaptés aux fonctions des utilisateurs.",
    recommandationDetails: "Analysez qui a besoin d’accéder à quelles données en fonction de leur rôle. Implémentez des contrôles d’accès basés sur des droits utilisateur spécifiques. Suivez et révisez régulièrement les droits accordés aux collaborateurs. Mettez en place une procédure pour restreindre rapidement les accès en cas de départ d’un employé."
  },
  {
    id: '5.5',
    isYesCase: false,
    isNoCase: true,
    illumination: "Les données transférées en dehors de l’UE doivent respecter des garanties spécifiques, comme les clauses contractuelles types ou des certifications. L’UE impose des règles strictes pour les transferts internationaux de données.",
    recommandationTitle: "Adaptez vos contrats en fonction de la localisation géographique des partenaires.",
    recommandationDetails: "Identifiez les partenaires situés hors UE traitant vos données. Mettez à jour vos contrats pour inclure des clauses spécifiques au RGPD. Assurez-vous que ces partenaires respectent des garanties équivalentes à celles du RGPD (ex. Privacy Shield, clauses types). Documentez vos démarches pour prouver votre conformité."
  },
  {
    id: '5.6',
    isYesCase: false,
    isNoCase: true,
    illumination: "Garantir la sécurité des données signifie mettre en œuvre des mesures techniques (chiffrement, sauvegardes) et organisationnelles (formations, politiques internes) pour protéger les données contre toute violation ou perte.",
    recommandationTitle: "Implémentez des mesures techniques et organisationnelles pour sécuriser les données.",
    recommandationDetails: "Effectuez une évaluation de vos pratiques actuelles en matière de sécurité. Implémentez des outils tels que le chiffrement des données et des pare-feu. Sensibilisez vos équipes à la gestion sécurisée des informations. Surveillez en continu vos systèmes pour détecter et prévenir les incidents."
  },
  {
    id: '5.7',
    isYesCase: false,
    isNoCase: true,
    illumination: "Les tests d’accessibilité permettent de vérifier que seuls les utilisateurs autorisés peuvent accéder aux systèmes. Ces tests aident à identifier et corriger les failles éventuelles dans les systèmes de sécurité.",
    recommandationTitle: "Testez régulièrement l’accessibilité et la sécurité de vos systèmes.",
    recommandationDetails: "Planifiez des tests réguliers sur vos systèmes (ex. audits internes, tests d’intrusion). Engagez un expert en sécurité informatique pour évaluer vos systèmes. Analysez les résultats des tests et mettez en œuvre les recommandations. Révisez vos pratiques d'accès en fonction des conclusions."
  },
  {
    id: '5.8',
    isYesCase: true,
    isNoCase: true,
    illumination: "Pour garantir la sécurité des données dans le cadres des sociétés tierces, il est nécessaire d'évaluer la fiabilité de vos sous-traitants et de contractualiser leurs obligations",
    recommandationTitle: "Vérifiez la fiabilité de vos sous-traitants à respecter la protection de la vie privée",
    recommandationDetails: "Envoyez à vos sous-traitant des questionnaires d'évaluation de leurs politiques de protection des données, contractualisez sous forme d'avenant leurs engagements vis-à-vis du Règlement, organisez les audits de leurs organisations"
  },
  {
    id: '5.9',
    isYesCase: false,
    isNoCase: true,
    illumination: "Sécuriser l’accès signifie protéger les serveurs et postes de travail contre tout accès non autorisé. Cela inclut des mots de passe robustes, une protection antivirus et des restrictions sur les connexions externes.",
    recommandationTitle: "Sécurisez l’accès aux serveurs et postes utilisateurs avec des mesures adaptées.",
    recommandationDetails: "Installez des solutions de sécurité sur tous les serveurs et postes (antivirus, pare-feu). Implémentez une politique de mots de passe sécurisés pour vos utilisateurs. Restreignez les accès physiques aux serveurs et mettez en place des audits réguliers. Assurez-vous que tous les systèmes soient à jour avec les dernières mises à jour de sécurité."
  },
  {
    id: '5.10',
    isYesCase: false,
    isNoCase: true,
    illumination: "Une procédure de désactivation des accès est essentielle pour éviter qu’un ancien collaborateur puisse encore accéder aux données sensibles ou aux systèmes après son départ.",
    recommandationTitle: "Désactivez rapidement les accès des collaborateurs ayant quitté l’entreprise.",
    recommandationDetails: "Rédigez une procédure claire pour désactiver rapidement les accès d’un collaborateur. Intégrez cette procédure dans votre processus de départ des employés. Assurez-vous que tous les accès (systèmes, emails, outils externes) soient supprimés ou révoqués. Testez régulièrement la bonne application de cette procédure."
  },
  {
    id: '5.11',
    isYesCase: false,
    isNoCase: true,
    illumination: "Les personnes ont le droit de demander la suppression, la modification ou le transfert de leurs données à un autre service. Vous devez leur fournir des informations claires sur ces droits et comment les exercer.",
    recommandationTitle: "Informez les individus de leurs droits sur leurs données (effacement, modification).",
    recommandationDetails: "Ajoutez des informations sur les droits des personnes dans votre politique de confidentialité. Mettez en place un processus pour gérer les demandes de droits (information, effacement...). Formez vos équipes pour répondre aux demandes des utilisateurs rapidement et correctement. Tenez un registre des demandes et des réponses apportées."
  },
  {
    id: '5.12',
    isYesCase: false,
    isNoCase: true,
    illumination: "Chaque traitement de données doit reposer sur une base légale (consentement, contrat, obligation légale, etc.). Les personnes concernées doivent savoir pourquoi leurs données sont collectées et sur quelle base.",
    recommandationTitle: "Expliquez clairement la base légale de vos traitements de données.",
    recommandationDetails: "Identifiez la base légale de chaque traitement que vous réalisez. Mettez à jour vos politiques pour inclure cette information. Informez clairement les utilisateurs (via des formulaires, conditions d’utilisation) de la base légale applicable. Revoyez régulièrement vos bases légales pour vous assurer de leur pertinence."
  },
  {
    id: '6.1',
    isYesCase: false,
    isNoCase: true,
    illumination: "Un processus d'audit de conformité permet de vérifier que votre organisation respecte les règles du RGPD. Ces audits doivent être réguliers et inclure un examen des politiques, des pratiques et des systèmes liés à la protection des données.",
    recommandationTitle: "Mettez en place des audits réguliers pour vérifier votre conformité RGPD.",
    recommandationDetails: "Mettez en place un calendrier d'audits réguliers pour vérifier la conformité RGPD. Désignez une personne ou une équipe responsable de ces audits. Utilisez une checklist RGPD pour couvrir tous les aspects (registre des traitements, mesures de sécurité, droits des individus, etc.). Documentez les résultats des audits et mettez en œuvre les recommandations."
  },
  {
    id: '6.2',
    isYesCase: false,
    isNoCase: true,
    illumination: "Un responsable des audits est une personne dédiée à la vérification et à l'amélioration de la conformité RGPD. Il doit avoir une bonne connaissance des réglementations et être capable d'évaluer les pratiques de l'organisation.",
    recommandationTitle: "Désignez un responsable pour superviser ces audits.",
    recommandationDetails: "Désignez une personne ou une équipe en interne pour s'occuper des audits. Assurez-vous que cette personne soit formée à la conformité RGPD. Prévoyez des réunions régulières pour évaluer les progrès et les besoins en conformité. Intégrez ce rôle dans votre structure organisationnelle."
  },
  {
    id: '6.3',
    isYesCase: false,
    isNoCase: true,
    illumination: "Un audit externe indépendant fournit un regard objectif sur vos pratiques de sécurisation des données. Il permet d'identifier des vulnérabilités que vous pourriez ne pas avoir détectées en interne.",
    recommandationTitle: "Faites réaliser des audits externes pour évaluer vos pratiques de sécurisation.",
    recommandationDetails: "Engagez un partenaire externe spécialisé en audits de sécurité des données. Planifiez un audit complet couvrant vos systèmes, politiques et processus. Analysez les recommandations fournies et mettez-les en œuvre rapidement. Conservez une copie des rapports pour prouver vos efforts de conformité."
  },
  {
    id: '6.4',
    isYesCase: false,
    isNoCase: true,
    illumination: "Une certification comme l’ISO 27001 atteste que votre organisation respecte des normes strictes en matière de sécurité des informations. Cela renforce la confiance des clients et partenaires.",
    recommandationTitle: "Obtenez une certification officielle pour renforcer la sécurité de vos données.",
    recommandationDetails: "Évaluez la pertinence d'une certification pour votre organisation. Préparez-vous à cette certification en suivant les étapes nécessaires (mise en conformité, audits internes, etc.). Contactez un organisme de certification reconnu. Maintenez cette certification en respectant les exigences de renouvellement."
  },
  {
    id: '7.1',
    isYesCase: false,
    isNoCase: true,
    illumination: "Les sous-traitants qui traitent des données personnelles pour votre compte doivent signer un contrat ou un avenant spécifique garantissant leur conformité au RGPD. Cela inclut leurs obligations et vos attentes en matière de protection des données.",
    recommandationTitle: "Intégrez des avenants RGPD dans les contrats avec vos sous-traitants.",
    recommandationDetails: "Identifiez tous vos sous-traitants qui traitent des données personnelles. Ajoutez un avenant RGPD à leurs contrats, spécifiant leurs obligations. Vérifiez régulièrement leur conformité. Conservez une copie des contrats pour démontrer votre diligence."
  },
  {
    id: '7.2',
    isYesCase: false,
    isNoCase: true,
    illumination: "Le RGPD impose des règles strictes pour les transferts de données personnelles en dehors de l'UE. En l'absence de statut d'adéquation , des clauses contractuelles spécifiques (comme les clauses contractuelles types) doivent être incluses pour garantir la protection des données.",
    recommandationTitle: "Incluez dans vos contrats des clauses pour les transferts de données hors UE et hors pays adéquates.",
    recommandationDetails: "Identifiez les sous-traitants situés en dehors de l'UE ou utilisant des prestataires hors UE. En l'absence de statut d'adéquation, mettez à jour vos contrats pour inclure des clauses conformes au RGPD. Assurez-vous que les garanties offertes respectent les exigences de la CNIL. Documentez ces démarches pour prouver votre conformité.", },
  {
    id: '7.3',
    isYesCase: false,
    isNoCase: true,
    illumination: "La suppression ou destruction des données une fois qu'elles ne sont plus nécessaires est une obligation du RGPD. Cela limite les risques de fuite ou d'usage abusif.",
    recommandationTitle: "Établissez des processus pour la suppression ou destruction des données en fin de contrat.",
    recommandationDetails: "Définissez des règles de conservation pour chaque type de données (durée légale, utilité). Mettez en place des procédures automatisées ou manuelles pour supprimer les données une fois les délais atteints. Vérifiez que vos sous-traitants appliquent aussi ces règles. Documentez les processus de destruction."
  },
  {
    id: '7.4',
    isYesCase: false,
    isNoCase: true,
    illumination: "En cas de fin de contrat avec un sous-traitant, le RGPD exige que les données soient restituées ou détruites de manière sécurisée pour éviter tout usage non autorisé.",
    recommandationTitle: "Garantissez la restitution ou destruction des données après la fin d’un partenariat.",
    recommandationDetails: "Incluez dans vos contrats une clause obligeant le sous-traitant à restituer ou détruire les données à la fin du contrat. Vérifiez que les processus de destruction sont sécurisés (certificats de destruction, etc.). Surveillez l’application de cette obligation au moment de la rupture de l’accord. Documentez ces étapes pour prouver votre conformité."
  },
  {
    id: '8.1',
    isYesCase: false,
    isNoCase: true,
    illumination: "Une analyse d'impact (PIA) est requise pour tout traitement susceptible d'engendrer un risque élevé pour les droits et libertés des personnes. Elle identifie les risques et prévoit des mesures pour les minimiser.",
    recommandationTitle: "Créez une procédure d’analyse d’impact pour évaluer les risques des nouveaux traitements.",
    recommandationDetails: "Identifiez les traitements nécessitant une analyse d’impact (par exemple, données sensibles). Rédigez une procédure pour conduire ces analyses (basée sur les recommandations de la CNIL). Formez vos équipes à mener ces analyses. Documentez et conservez les analyses réalisées."
  },
  {
    id: '8.2',
    isYesCase: false,
    isNoCase: true,
    illumination: "Avant de lancer un nouveau traitement de données, il est essentiel d'évaluer les risques pour protéger les individus concernés. Cela fait partie des exigences de la conformité RGPD.",
    recommandationTitle: "Réalisez une analyse d’impact avant tout lancement de projet impliquant des données sensibles.",
    recommandationDetails: "Intégrez une analyse d’impact comme étape obligatoire avant tout nouveau projet impliquant des données personnelles sensibles. Collaborez avec les équipes projets pour identifier les risques potentiels. Proposez des ajustements basés sur les résultats de l’analyse. Validez le projet uniquement après avoir mis en œuvre des mesures correctives."
  },
  {
    id: '8.3',
    isYesCase: false,
    isNoCase: true,
    illumination: "Si une analyse d'impact révèle des risques élevés qui ne peuvent pas être atténués, vous devez consulter la CNIL avant de démarrer le traitement.",
    recommandationTitle: "Consultez la CNIL en cas de risques élevés non atténués.",
    recommandationDetails: "Identifiez les situations nécessitant une consultation de la CNIL. Préparez les dossiers requis pour cette consultation (analyse d’impact, mesures proposées). Contactez la CNIL en cas de besoin avant de débuter le traitement. Attendez leur retour avant toute mise en œuvre."
  },
  {
    id: '8.4',
    isYesCase: false,
    isNoCase: true,
    illumination: "Lorsque vous sous-traitez des données personnelles, il est impératif d'avoir des clauses contractuelles spécifiques dans vos contrats. Ces clauses doivent préciser les obligations du sous-traitant concernant la sécurité, la confidentialité, et la restitution ou destruction des données après la fin du contrat.",
    recommandationTitle: "Ajoutez des clauses RGPD dans vos contrats de sous-traitance pour préciser les obligations en matière de confidentialité, sécurité, et gestion des données.",
    recommandationDetails: "Revoyez vos contrats avec vos sous-traitants pour inclure des clauses spécifiques au RGPD. Définissez clairement les rôles et responsabilités en matière de protection des données. Assurez-vous que ces clauses couvrent la confidentialité, la sécurité, et la gestion des incidents. Faites signer ces avenants ou contrats mis à jour à tous vos sous-traitants."
  },
  {
    id: '8.5',
    isYesCase: false,
    isNoCase: true,
    illumination: "La traçabilité consiste à enregistrer qui accède à vos systèmes, quand, et pourquoi. Cela permet de détecter des comportements inhabituels, de répondre aux incidents de sécurité et de respecter les obligations légales de surveillance.",
    recommandationTitle: "Configurez vos systèmes pour générer des journaux de connexion, analysez-les régulièrement et utilisez-les pour détecter des anomalies ou comportements suspects.",
    recommandationDetails: "Configurez vos systèmes d’information pour qu’ils génèrent automatiquement des journaux de connexion (logs). Stockez ces enregistrements dans un environnement sécurisé et accessible uniquement aux personnes autorisées. Analysez régulièrement ces logs pour détecter des comportements suspects. Formez vos équipes à utiliser ces outils pour surveiller les accès et réagir aux anomalies."
  },
  {
    id: '9.1',
    isYesCase: false,
    isNoCase: true,
    illumination: "Pour garantir la conformité RGPD, il est essentiel de connaître tous les sous-traitants qui traitent des données pour votre compte. Cela inclut les prestataires techniques, commerciaux ou administratifs.",
    recommandationTitle: "Identifiez l'ensemble des sous-traitants de vos données et leurs propres sous-traitants,",
    recommandationDetails: "Dressez une liste complète de vos sous-traitants. Identifiez le type de données qu’ils traitent et leurs rôles spécifiques. Vérifiez leur conformité RGPD (contrats, pratiques). Mettez à jour cette liste régulièrement."
  },
  {
    id: '9.2',
    isYesCase: false,
    isNoCase: true,
    illumination: "Avant de travailler avec un sous-traitant, vous devez vérifier qu’il respecte les exigences du RGPD, notamment en matière de sécurité et de gestion des données personnelles.",
    recommandationTitle: "Vérifiez la conformité de vos sous-traitants en lui envoyant un questionnaire dont vous garderez copie des résultats",
    recommandationDetails: "Envoyez un questionnaire de conformité RGPD à vos sous-traitants. Vérifiez leurs politiques et pratiques (sécurité, gestion des données). Conservez une trace écrite de leur conformité. Réévaluez leur conformité régulièrement."
  },
  {
    id: '9.3',
    isYesCase: false,
    isNoCase: true,
    illumination: "Les contrats avec vos sous-traitants doivent inclure des clauses précises définissant leurs obligations en matière de protection des données, comme prévu par le RGPD.",
    recommandationTitle: "Veillez à ce que les clauses relatives à la protection des données figurent dans vos contrats de sous-traitance,",
    recommandationDetails: "Révisez tous les contrats pour inclure des clauses RGPD obligatoires. Précisez les responsabilités et les engagements de chaque partie. Assurez-vous que ces clauses couvrent les transferts de données, la sécurité et la confidentialité. Faites signer ces avenants ou contrats actualisés."
  },
  {
    id: '9.4',
    isYesCase: false,
    isNoCase: true,
    illumination: "Un process formalisé pour gérer vos sous-traitants garantit que les relations respectent les règles RGPD, quel que soit leur emplacement géographique.",
    recommandationTitle: "Prouvez que vous avez sélectionné un partenaire qui respecte le RGPD",
    recommandationDetails: "Définissez un process clair pour la sélection, l’évaluation et le suivi de vos sous-traitants. Incluez des étapes spécifiques pour les partenaires hors UE (clauses types, garanties). Formez vos équipes à suivre ce process. Documentez toutes les étapes pour démontrer votre conformité."  
  },
  {
    id: '9.5',
    isYesCase: false,
    isNoCase: true,
    illumination: "La possibilité d’auditer vos sous-traitants est cruciale pour vérifier qu’ils respectent leurs obligations en matière de RGPD. Cela doit être spécifié dans le contrat.",
    recommandationTitle: "Vérifiez sur pièce et sur place la confrmité de vos sous-traitants",
    recommandationDetails: "Modifiez vos contrats pour inclure une clause d’audit RGPD. Précisez les modalités (périodicité, type d’audit, responsabilités). Organisez des audits réguliers avec vos sous-traitants critiques. Conservez les résultats pour améliorer les relations contractuelles."
  },
  {
    id: '9.6',
    isYesCase: false,
    isNoCase: true,
    illumination: "Le DPO doit être informé de tout nouveau partenariat impliquant des traitements de données pour évaluer les risques et s’assurer que le sous-traitant respecte le RGPD.",
    recommandationTitle: "Informez votre DPO de vos projets impactants votre système d'information",
    recommandationDetails: "Mettez en place une procédure informant le DPO de tout nouveau sous-traitant. Permettez-lui d’examiner les contrats avant signature. Formez vos équipes pour inclure cette étape dans le process d’achat ou de partenariat. Documentez toutes les communications avec le DPO."
  },
  {
    id: '9.7',
    isYesCase: false,
    isNoCase: true,
    illumination: "Donner au DPO le pouvoir de s'opposer à un sous-traitant garantit que seules des entreprises conformes et fiables sont sélectionnées pour traiter vos données.",
    recommandationTitle: "Impliquez votre DPO dans le choix d'un nouveau sous-traitant,",
    recommandationDetails: "Intégrez dans vos procédures le rôle du DPO dans la validation des sous-traitants. Documentez les motifs d’approbation ou de refus d’un sous-traitant. Assurez-vous que le DPO ait accès aux informations nécessaires pour évaluer les risques. Formez vos équipes à respecter cette étape avant finalisation des partenariats."
  },
];

export function canDisplayIcons(id, answer, options = {}) {
  const tip = tips.find(tip => tip.id === id);
  if (!tip) return false;

  const { onlyNoCase = false, onlyYesCase = false } = options;

  if (onlyYesCase) return tip.isYesCase && answer === 'Oui';
  if (onlyNoCase) return tip.isNoCase && answer === 'Non';
  
  if (tip.isYesCase && answer === 'Oui') return true;
  if (tip.isNoCase && answer === 'Non') return true;
  
  return false;
}

export function canDisplayRecommendationIcon(ids, answers) {
  const tipsToDisplay = tips.filter(tip => ids.includes(tip.id));
  if (!tipsToDisplay.length) return false;
  
  return tipsToDisplay.some(tip => {
    if (tip.isYesCase && answers[tip.id] === 'Oui') return true;
    if (tip.isNoCase && answers[tip.id] === 'Non') return true;

    return false;
  });
}

export function getIllumnation(id) {
  return tips.find(tip => tip.id === id)?.illumination || '';
}

export function getRecommendationTitle(id) {
  return tips.find(tip => tip.id === id)?.recommandationTitle || '';
}

export function getRecommendationDetails(id) {
  return tips.find(tip => tip.id === id)?.recommandationDetails || '';
}
<template>
  <div id="Questionnaire-rgbd">
    <template v-if="loading_qst">
         <content-placeholders class="">
          <content-placeholders-text :lines="10" />
        </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-flex tw-items-center tw-justify-between">
        <EntrepriseHeaderBlockTextComponent
          title="Questionnaire RGPD"
          description="Répondez à toutes les questions pour établir votre score."
        >
        </EntrepriseHeaderBlockTextComponent>
      </div>
      <div class="tw-w-full marginTop" id="table">
        <div class="tw-font-semibold tw-mb-4 tw-text-sm">
          Lorsque vous répondrez par « Non » vous bénéficierez d'éclairages
          <svg class="tw-inline-block tw-ml-1" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8.62C16 11.2 14.73 13.185 12.798 14.492C12.348 14.796 12.123 14.948 12.012 15.122C11.902 15.294 11.863 15.522 11.788 15.976L11.728 16.329C11.596 17.127 11.529 17.526 11.249 17.763C10.969 18 10.565 18 9.756 18H7.144C6.335 18 5.931 18 5.651 17.763C5.371 17.526 5.305 17.127 5.171 16.329L5.113 15.976C5.037 15.523 5 15.296 4.89 15.124C4.78 14.952 4.554 14.798 4.103 14.49C2.192 13.183 1 11.199 1 8.62C1 4.413 4.358 1 8.5 1C9.00387 0.999471 9.50648 1.0504 10 1.152" stroke="#6C5DD3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.5 18V19C10.5 19.943 10.5 20.414 10.207 20.707C9.914 21 9.443 21 8.5 21C7.557 21 7.086 21 6.793 20.707C6.5 20.414 6.5 19.943 6.5 19V18M13.5 1L13.758 1.697C14.096 2.611 14.265 3.068 14.598 3.401C14.932 3.735 15.389 3.904 16.303 4.242L17 4.5L16.303 4.758C15.389 5.096 14.932 5.265 14.599 5.598C14.265 5.932 14.096 6.389 13.758 7.303L13.5 8L13.242 7.303C12.904 6.389 12.735 5.932 12.402 5.599C12.068 5.265 11.611 5.096 10.697 4.758L10 4.5L10.697 4.242C11.611 3.904 12.068 3.735 12.401 3.402C12.735 3.068 12.904 2.611 13.242 1.697L13.5 1Z" stroke="#6C5DD3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        
        <ChapterComponent
          v-for="(chapter, key) in blueprint"
          :key="key"
          :chapter="chapter"
          :chapter-order-number="key+1"
          :saved-chapter="quizDetail[key]"
          :can-show-questions="selectChapitre == chapter.id"
          hide-recommendations
          @selectChapter="handleChapitre"
          @input-changed="hanleUserResponds"
        ></ChapterComponent>
      </div>
      <div class="tw-flex tw-items-center tw-justify-center tw-space-x-20">
        <v-btn
          elevation="1"
          text
          rounded
          large
          @click="Enregistrer()"
          class="victor-button-bg"
        >
          Enregistrer
        </v-btn>
        <v-btn elevation="1" text rounded large @click="showPopup()">
          Valider
        </v-btn>
      </div>
      <div class="tw-flex tw-justify-between">
        <p>
          Vous pouvez enregistrer à tout moment pour sauvegarder vos réponses.
          Vous pourrez revenir sur chacune d’entre-elles par la suite.
        </p>
        <p>
          Une fois votre questionnaire validé, vous ne pourrez plus modifier vos
          réponses. Votre évaluation vous sera transmise par email.
        </p>
      </div>
      <AccepterPopup
        :openPopup="openPopup"
        @fetchData="Valider()"
        @close="closePopup"
      />
    </template>
  </div>
</template>
<script>
import AccepterPopup from "@/features/company/components/Questionnaire/AccepterPopup";
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import ChapterComponent from "@/features/company/components/Questionnaire/RGPDChapterComponent";
// import SpinnerViqtor from '@/components/common/SpinnerViqtor';

import {
  getQuiBlueprint,
  storeDraftQuiz,
  getQuizDetail,
  storeQuiz,
} from "../../../services/appApi";

export default {
  name: "QuestionnaireRGBDPage",
  computed: {
    questionnaireScore() {
      var sum = this.blueprint.reduce((acc, item) => acc + item.score, 0);
      var lenght = this.blueprint.length;
      return (sum / lenght).toFixed(2);
    },
  },
  components: {
    EntrepriseHeaderBlockTextComponent,
    ChapterComponent,
    AccepterPopup,
    // SpinnerViqtor
  },
  data() {
    return {
      blueprint: [],
      quizDetail: [],
      chapters: [],
      selectChapitre: null,
      openPopup: false,
      loading_qst:false,
      loading:true
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    showPopup() {
      this.openPopup = true;
    },
    closePopup(value) {
      this.openPopup = value;
    },
    async fetchData() {
      this.loading_qst=true;
      await getQuiBlueprint().then((response) => {
        this.blueprint = response.data.data;
        this.chapters = this.blueprint.map((item) => {
          return {
            chapterId: item.id,
            questions: item.questions.map((q) => {
              return {
                questionId: q.id,
                responses: q.responses.map((r) => {
                  return {
                    id: r.id,
                    label: r.label,
                    responseId: null,
                  };
                }),
              };
            }),
          };
        });
        this.quizDetail = this.chapters.map(chapter => {
          return {
            chapterId: chapter.chapterId,
            questions: chapter.questions.map((question) => {
              return {
                questionId: question.questionId,
                responses: question.responses.map((response) => {
                  const isAnswerIncluded = !!this.quizDetail
                    .find((item) => item.chapterId == chapter.chapterId)
                    .questions.find((q) => q.questionId == question.questionId)
                    .responses.find((r) => r.id == response.id)

                  return {
                    id: response.id,
                    label: response.label,
                    responseId: isAnswerIncluded ? response.id : null,
                  };
                }),
              };
            }),
          }
        })
      })
      .catch((err) => {
        console.log(err);
        this.$snackbar.showAlertMessage({
          message: err.response.data.message,
          type: "error",
        });
      })
      .finally(() => {
          this.loading_qst = false;
      });
    },
    loadData() {
      this.loading = true;
      getQuizDetail().then((response) => {
        if(response.data.data ){
          this.quizDetail = response.data.data.quizChapters.map((item) => {
            const questions = item.quizQuestions.map((question) => {
              return {
                questionId: question.questionId,
                responses: question.responses,
              };
            });
            return { chapterId: item.chapterId, questions: questions };
          });
        }
      }).then(()=>{
        this.fetchData();
        this.loading = false;
      });
    },
    handleChapitre(id) {
      if (this.selectChapitre == id) {
        this.selectChapitre = null;
      } else {
        this.selectChapitre = id;
      }
    },
    hanleUserResponds(chapterId, questions) {
      this.chapters = this.chapters.map((item) => {
        if (item.chapterId == chapterId) {
          return {
            chapterId,
            questions,
          };
        }
        return item;
      });
    },
    Enregistrer() {
      const data = {
        chapters: this.chapters,
      };
      storeDraftQuiz(data).then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
        })
        .catch((err) => {
          this.$snackbar.showAlertMessage({
            message: err.response.data.message,
            type: "error",
          });
        });
      // this.$router.push({
      //   name: "Company.MonEntreprisePage.Questionnaire.RGBD.valid",
      // });
    },
    Valider() {
      const data = {
        chapters: this.chapters,
      };
      storeQuiz(data)
        .then(() => {
          this.$router.push({
            name: "Company.MonEntreprisePage.Questionnaire.RGBD.invitation",
          });
        })
        .catch((err) => {
          if (err.response.data.statusCode === 400) {
            this.$snackbar.showAlertMessage({
              message: err.response.data.message,
              type: "error",
            });
          }
          this.openPopup = false;
        })
        .finally(() => {
          this.openPopup = false;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped></style>

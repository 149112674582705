<template>
  <div id="Questionnaire-detail">
    <div class="tw-flex tw-items-center tw-justify-between">
      <EntrepriseHeaderBlockTextComponent
        :title="`Détails de l’évaluation de la conformité RGPD de ${socialReason}`"
        description="Vous ne pouvez plus modifier votre évaluation."
        :descriptionColor="theme.ctaBgColor"
      />
      <div class="tw-flex tw-items-center tw-justify-between">
        <v-btn
          :loading="downoald_loading"
          elevation="1"
          text
          rounded
          large
          @click="telecharger()"
          class="tw-text-white"
        >
          Télécharger
        </v-btn>
      </div>
    </div>

    <div v-if="referrer.slug === 'gpmse' || isFreeTier" style="background-color: #F5F5F5; margin-top: 25px; padding: 16px 19px; border-radius: 10px;">
      <b>Améliorez rapidement votre score en effectuant 4 actions simples et immédiates.</b>
      <p style="margin-bottom: 0;" class="tw-text-sm">
        Pour effectuer et automatiser ces actions en quelques clics, souscrivez un abonnement à Viqtor® à partir de 26€HT/mois sans engagement, <a href="#" class="tw-underline">cliquez ICI</a>. <br />
        Pour être accompagné ou pour une démo, prenez contact avec nous, c’est <a href="mailto:contact@viqtor.eu" class="tw-underline">ICI</a>
      </p>
    </div>

    <template v-if="loading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-w-full marginTop" id="table">
        <div class="tw-font-semibold tw-mb-4 tw-text-sm tw-flex" :style="{color: theme.ctaBgColor}">
          Cliquez sur les onglets pour accéder aux réponses de votre évaluation et cliquez sur &nbsp;
          <svg width="8" height="20" viewBox="0 0 8 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.875 0C2.84729 0 1.86166 0.408258 1.13496 1.13496C0.408258 1.86166 0 2.84729 0 3.875C0 6.795 1.207 10.427 1.813 12.074C1.97001 12.4939 2.25213 12.8556 2.62124 13.1101C2.99035 13.3645 3.42867 13.4996 3.877 13.497C4.781 13.497 5.616 12.955 5.94 12.079C6.546 10.439 7.75 6.825 7.75 3.875C7.75 2.84729 7.34174 1.86166 6.61504 1.13496C5.88834 0.408258 4.90271 0 3.875 0ZM1.5 3.875C1.5 3.24511 1.75022 2.64102 2.19562 2.19562C2.64102 1.75022 3.24511 1.5 3.875 1.5C4.50489 1.5 5.10898 1.75022 5.55438 2.19562C5.99978 2.64102 6.25 3.24511 6.25 3.875C6.25 6.53 5.139 9.918 4.533 11.559C4.48158 11.6901 4.39126 11.8024 4.27417 11.8807C4.15708 11.959 4.01883 11.9996 3.878 11.997C3.7366 11.9998 3.59773 11.9591 3.48022 11.8804C3.36271 11.8017 3.27222 11.6888 3.221 11.557C2.614 9.905 1.5 6.499 1.5 3.875ZM3.876 14.999C3.21269 14.999 2.57655 15.2625 2.10753 15.7315C1.6385 16.2006 1.375 16.8367 1.375 17.5C1.375 18.1633 1.6385 18.7994 2.10753 19.2685C2.57655 19.7375 3.21269 20.001 3.876 20.001C4.53931 20.001 5.17545 19.7375 5.64447 19.2685C6.1135 18.7994 6.377 18.1633 6.377 17.5C6.377 16.8367 6.1135 16.2006 5.64447 15.7315C5.17545 15.2625 4.53931 14.999 3.876 14.999ZM2.875 17.5C2.875 17.3685 2.90089 17.2384 2.9512 17.1169C3.0015 16.9955 3.07523 16.8851 3.16819 16.7922C3.26114 16.6992 3.37149 16.6255 3.49293 16.5752C3.61438 16.5249 3.74455 16.499 3.876 16.499C4.00745 16.499 4.13762 16.5249 4.25907 16.5752C4.38051 16.6255 4.49086 16.6992 4.58381 16.7922C4.67676 16.8851 4.7505 16.9955 4.8008 17.1169C4.85111 17.2384 4.877 17.3685 4.877 17.5C4.877 17.7655 4.77154 18.0201 4.58381 18.2078C4.39609 18.3955 4.14148 18.501 3.876 18.501C3.61052 18.501 3.35591 18.3955 3.16819 18.2078C2.98046 18.0201 2.875 17.7655 2.875 17.5Z" fill="#E8973E"/>
          </svg>
          &nbsp; pour consulter les recommandations
        </div>
        
        <ChapterComponent
          v-for="(chapter, key) in blueprint"
          :key="key"
          :chapter="chapter"
          :chapter-order-number="key+1"
          :saved-chapter="quizDetail[key]"
          :can-show-questions="selectChapitre == chapter.id"
          readonly
          hide-illuminations
          @selectChapter="handleChapitre"
        ></ChapterComponent>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-pb-4">
        <v-btn elevation="1" text rounded large @click="Retour()"> Retour </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { getQuizDetail, getQuiBlueprint } from "../../../services/appApi";
import ChapterComponent from "@/features/company/components/Questionnaire/RGPDChapterComponent";
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import jsPDF from "jspdf"
import "jspdf-autotable";
export default {
  components: {
    EntrepriseHeaderBlockTextComponent,
    ChapterComponent,
  },
  data() {
    return {
      downoald_loading: false,
      loading: false,
      blueprint: [],
      selectChapitre: null,
      quizDetail: [],
      totalScorePercent: 0,
      totalScoreStatus: "",
    };
  },
  computed: {
    socialReason() {
      return this.$store.state.user.workInCompany.socialReason;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
    referrer() {
      return this.$store.state.referrer;
    },
    isFreeTier() {
      return this.$store.state.user.workInCompany.planPrice?.price === 0;
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      getQuizDetail().then((response) => {
        if(response.data.data){
          this.quizDetail = response.data.data.quizChapters.map((item) => {
            const questions = item.quizQuestions.map((question) => {
              return {
                questionId: question.questionId,
                responses: question.responses,
              };
            });
            return { 
              chapterId: item.chapterId, 
              questions: questions,
              scorePercent: item.scorePercent,
            };
          });

          this.totalScorePercent = response.data.data.scorePercent;
          this.totalScoreStatus = response.data.data.status;
        }
      }).then(()=>{
        this.fetchData();
        this.loading = false;
      });
    },
    async fetchData() {
      this.loading = true;
      await getQuiBlueprint().then((response) => {
        this.blueprint = response.data.data;
        this.chapters = this.blueprint.map((item) => {
          return {
            chapterId: item.id,
            questions: item.questions.map((q) => {
              return {
                questionId: q.id,
                responses: q.responses.map((r) => {
                  return {
                    id: r.id,
                    label: r.label,
                    responseId: null,
                  };
                }),
              };
            }),
          };
        });
        this.quizDetail = this.chapters.map(chapter => {
          return {
            chapterId: chapter.chapterId,
            scorePercent: this.quizDetail.find(item => item.chapterId == chapter.chapterId).scorePercent,
            questions: chapter.questions.map((question) => {
              return {
                questionId: question.questionId,
                responses: question.responses.map((response) => {
                  const isAnswerIncluded = !!this.quizDetail
                    .find((item) => item.chapterId == chapter.chapterId)
                    .questions.find((q) => q.questionId == question.questionId)
                    .responses.find((r) => r.id == response.id)

                  return {
                    id: response.id,
                    label: response.label,
                    responseId: isAnswerIncluded ? response.id : null,
                  };
                }),
              };
            }),
          }
        })
      })
      .catch((err) => {
        console.log(err);
        this.$snackbar.showAlertMessage({
          message: err?.response?.data?.message || 'Une erreur est survenue.',
          type: "error",
        });
      })
      .finally(() => {
        this.loading = false;
      });
    },
    handleChapitre(id) {
      if (this.selectChapitre === id) {
        this.selectChapitre = null;
      } else {
        this.selectChapitre = id;
      }
    },
    Retour() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Questionnaire",
      });
    },
    telecharger() {
      this.downoald_loading = true;
      let doc = new jsPDF();
      // const width        = doc.internal.pageSize.getWidth();
      // const height       = doc.internal.pageSize.getHeight();
      const h3_font_size = 10;

      doc.addImage("/images/logo.png", "JPEG", 75, 7, 60, 20);

      doc.setFontSize(14);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      doc.text("Résultat du questionnaire",75, 40);

      this.blueprint.forEach((row, index) => {
        let quiz_data = [];
        const chapterScorePercent = this.quizDetail.find(item => item.chapterId == row.id).scorePercent;

        row.questions.forEach((item, indexJ) => {
          const selectedResponses = this.quizDetail.find(chapter => chapter.chapterId == row.id)
            .questions.find(question => question.questionId == item.id)
            .responses.filter(response => response.responseId);

          quiz_data.push([
            {
              content: `${index + 1}.${indexJ + 1} )  ${item.label}`,
              styles: { halign: "left" }
            },
            {
              content: `${selectedResponses.length > 0 
                ? selectedResponses.map(r => r.label).join(', ')
                : ''
              }`,
              styles: { halign: "center", valign: "middle", cellWidth: 45, fontSize: 12 }
            },
          ]);

        });
        let color = [];
        if (row.status === 'A') {
          color = [85, 140, 102];
        } else if (row.status === 'B') {
          color = [212, 151, 37];
        } else {
          color = [187, 69, 76];
        }

        if(quiz_data.length > 0){
          doc.setFontSize(h3_font_size);
          doc.autoTable({
            theme: 'plain',
            styles: { lineColor: [0, 0, 0], lineWidth: 0.3 },
            margin: { top: 60, left: 22,right: 22 },
            head: [[`${index + 1}. ${row.label}`, row.id != 10 ? `${chapterScorePercent} %` : '']],
            headStyles: {
              fillColor: color,
              textColor: [255, 255, 255],
              halign: "center",
              valign: "middle"
            },
            body: quiz_data,
          });
        }
      });

      let finalY = doc.previousAutoTable.finalY || 10;

      doc.setFontSize(14);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      doc.text(`Total : `,22,finalY + 20);
      if (this.totalScoreStatus === 'OK_GO') {
        doc.setTextColor(85, 140, 102);
      } else if (this.totalScoreStatus === 'GO_SR_A' || this.totalScoreStatus === 'GO_SR_B') {
        doc.setTextColor(212, 151, 37);
      } else {
        doc.setTextColor(187, 69, 76);
      }
      doc.text(`${this.totalScorePercent ? this.totalScorePercent.toFixed() : '0'} / 100`, 42, finalY + 20);

      doc.save("quiz.pdf");

      this.downoald_loading = false;
    },
  },
};
</script>
<style scoped></style>

<template>
  <v-dialog
    v-if="openPopup"
    v-model="openPopup"
    @click:outside="closePopup"
    scrollable
    width="48%"
    transition="dialog-bottom-transition"
  >
    <div id="deleteDialog">
      <div class="tw-flex tw-items-center tw-justify-center tw-w-full">
        <span class="tw-text-2xl" style="color: #52575C;">IMPORTANT</span>
        <svg class="tw-cursor-pointer img-close" @click="closePopup" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33352 0.211923L5.00009 3.87813L8.66666 0.223033C8.73885 0.149586 8.8255 0.0919159 8.92112 0.05367C9.01674 0.0154241 9.11927 -0.00256573 9.2222 0.000838218C9.42418 0.0139081 9.6145 0.100038 9.75762 0.243146C9.90075 0.386254 9.98689 0.576556 9.99996 0.778519C10.001 0.87782 9.98178 0.97629 9.94358 1.06796C9.90539 1.15962 9.84896 1.24258 9.77774 1.31179L6.10006 5.00022L9.77774 8.68864C9.92218 8.82863 10.0022 9.02194 9.99996 9.22191C9.98689 9.42387 9.90075 9.61418 9.75762 9.75729C9.6145 9.90039 9.42418 9.98652 9.2222 9.99959C9.11927 10.003 9.01674 9.98501 8.92112 9.94676C8.8255 9.90851 8.73885 9.85085 8.66666 9.7774L5.00009 6.1223L1.34464 9.7774C1.27244 9.85085 1.1858 9.90851 1.09017 9.94676C0.99455 9.98501 0.892029 10.003 0.789095 9.99959C0.583345 9.98888 0.388856 9.90234 0.243172 9.75667C0.0974876 9.611 0.0109371 9.41653 0.000227414 9.2108C-0.000777895 9.1115 0.0184003 9.01303 0.0565986 8.92136C0.094797 8.8297 0.151219 8.74674 0.222444 8.67754L3.90012 5.00022L0.211333 1.31179C0.142114 1.24164 0.0877451 1.15827 0.0514738 1.06665C0.0152026 0.975023 -0.00222666 0.877029 0.000227414 0.778519C0.0132986 0.576556 0.0994371 0.386254 0.242559 0.243146C0.385681 0.100038 0.576002 0.0139081 0.777984 0.000838218C0.880117 -0.00401225 0.982154 0.0122271 1.07773 0.0485439C1.17331 0.0848608 1.26039 0.140476 1.33352 0.211923Z" :fill="referer.theme.ctaBgColor"/>
        </svg>
      </div>

      <template>
        <div class="tw-mt-10"></div>
        <v-container fluid class="tw-w-full tw-flex">
          <div class="tw-w-1/12">
            <v-switch style="margin-top: 2rem;" v-model="switch1"></v-switch>
          </div>
          <div class="tw-w-10/12" style="color: black;" v-html="description1"></div>
        </v-container>

        <div class="tw-mt-10"></div>
        <v-container fluid class="tw-w-full tw-flex">
          <div class="tw-w-1/12">
            <v-switch style="margin-top: 2rem;" v-model="switch2"></v-switch>
          </div>
          <div class="tw-w-10/12" style="color: black;" v-html="description2"></div>
        </v-container>
      </template>

      <span class="tw-text-dark tw-bg-white tw-mt-10 tw-font-bold" style="color: #52575C;">
        Pour valoir ce que de droit
      </span>

      <v-btn
        elevation="1"
        text
        rounded
        large
        :disabled="!switch2 || !switch1"
        :loading="isSendingForm"
        @click="supprimerContact"
        class="tw-text-white disabled:tw-opacity-50"
        style="border-radius: 11px; margin-top: .75rem;"
      >
        Lu et approuvé
      </v-btn>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: "AccepterPopup",
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    handelSwitch() {
      return this.table_data1.filter((x) => x.Accepter == 0).length == 0;
    },
    referer() {
      return this.$store.state.referrer;
    }
  },

  data() {
    return {
      isSendingForm: false,
      description1:
        "<b style='display: block; margin-bottom: .5rem; color: #52575C;'>Déclaration de sincérité et d’exactitude des réponses</b>Je confirme avoir renseigné le présent questionnaire de manière sincère et exacte et déclare que les réponses apportées sont conformes au fonctionnement de notre organisation et démontrables à tout moment.",
      description2:
        "<b style='display: block; margin-bottom: .5rem; color: #52575C;'>Risques et responsabilités</b>J’ai pris connaissance :<br>- Qu’en cas de contrôle,  l’autorité compétente et notamment la CNIL pourra être amenée à nous demander de lui fournir la documentation des preuves de nos déclarations.<br>- Par ailleurs, dans le cadre de la labellisation, le GPMSE pourra en auditer le contenu et en vérifier sur pièces, les justifications.",
      switch1: false,
      switch2: false,
    };
  },
  methods: {
    supprimerContact() {
      this.$emit("fetchData");
      this.isSendingForm=true;
    },
    closePopup() {
      this.$emit("close", false);
      this.openPopup = false;
    },
  },
  watch: {
    openPopup(val){
      this.isSendingForm = !val;
    }
  },
};
</script>
<style lang="scss" scoped>
#deleteDialog {
  padding-top: 1rem;
  padding-bottom: 1rem;

  position: relative;
  img {
    height: 40px;
    width: 40px;
    margin: 20px auto 20px;
  }
  .img-close {
    height: 16px;
    width: 16px;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.v-btn.tw-text-white:disabled{
  color: #fff !important;
  opacity: 0.5;
}
</style>

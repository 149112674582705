<template>
  <div class="tw-mt-6 tw-flex" id="dashboard-block" v-if="!isLoading"
    :style="{ backgroundColor: `${referer.theme.ctaBgColor} !important` }">
    <div class="tw-w-3/4 tw-flex tw-flex-col tw-align-start">
      <h1 class="title-block-dashboard">{{ title }}</h1>
      <span class="text-block-dashboard tw-mt-4">
        {{ subtitle }}
      </span>
      <PrimaryButtonComponent
        :style="{
          backgroundColor: `${referer.theme.ctaTextColor} !important`,
          color: `${referer.theme.ctaBgColor} !important`,
        }"
        style="width: 200px;"
        class="tw-mt-6"
        elevation="0"
      ><span style="font-size: 16px; letter-spacing: normal; font-weight: 600;">Je souscrits</span></PrimaryButtonComponent>
    </div>
    <div class="tw-w-1/4 tw-flex tw-align-center tw-justify-end">
      <img src="@/assets/images/dashboard/dashboard-icon.png" width="150" alt="logo" />
    </div>
  </div>
  <div v-else>
    <div class="tw-mt-6 tw-flex tw-animate-pulse" id="dashboard-block-loading">
      <div class="tw-w-3/4 tw-flex tw-flex-col tw-align-start">
        <div class="tw-h-8 tw-bg-gray-200 tw-rounded tw-w-3/4"></div>
        <div class="tw-h-16 tw-bg-gray-200 tw-rounded tw-w-full tw-mt-4"></div>
      </div>
      <div class="tw-w-1/4 tw-flex tw-align-center tw-justify-end">
        <div class="tw-h-[150px] tw-w-[150px] tw-bg-gray-200 tw-rounded"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuiz } from "@/features/company/services/appApi";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
  name: "DashboardInvitationBlock",
  components: {
    PrimaryButtonComponent,
  },
  data() {
    return {
      content: [
        { // Score Quiz Rouge ou Orange
          title: "La gouvernance des données privées respectueuse du Règlement, vous n’y êtes pas encore mais vous pouvez y arriver !",
          subtitle: "Viqtor vous propose de vous accompagner dans toutes les étapes de votre démarche : votre conformité est à portée d’un clic !",
        },
        { // Score Quiz Vert
          title: "Vous avez construit une solide gouvernance des données… mais votre fragilité, ce sont désormais vos partenaires et fournisseurs.",
          subtitle: "Pour vous permettre de gérer ce risque majeur en quelques clics, ouvrez un compte VIQTOR qui vous permettra de mettre à jour automatiquement, dans la durée, l’évaluation de vos sous-traitants.",
        }
      ],
      title: "",
      subtitle: "",
      isLoading: true,
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    isFreePlan() {
      if (this.$store.getters.getAuthUser) {
        return this.$store.getters.getAuthUser.workInCompany.planPrice ? this.$store.getters.getAuthUser.workInCompany.planPrice.price === 0 : true;
      } else {
        return true;
      }
    },
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getQuiz()
        .then((res) => {
          this.scoreTotal = res.data.data.quiz.status;
  
          if (this.scoreTotal == "OK_GO") {
            this.title = this.content[1].title;
            this.subtitle = this.content[1].subtitle;
          } else if (
            this.scoreTotal =="GO_SR_A" ||
            this.scoreTotal =="GO_SR_B"
          ) {
            this.title = this.content[0].title;
            this.subtitle = this.content[0].subtitle;
          } else {
            this.title = this.content[0].title;
            this.subtitle = this.content[0].subtitle;
          }
  
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        });
    },
  }
}
</script>

<style scoped>

</style>

import Vue from "vue";
import App from "./App.vue";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import VueRouter from "vue-router";
import router from "./routes/router";
import store from "./store";
import snackbarPlugin from "./plugins/snackbar";
import { serverConfig } from "@/config/serverConfig";
import ApiService from "@/services/api.service";
import RichTextEditor from "@/components/common/RichTextEditor";
import JsonExcel from "vue-json-excel";
import VueContentPlaceholders from "vue-content-placeholders";
import VueGtag from "vue-gtag";
import VuePageTransition from 'vue-page-transition'
import Notifications from "vue-notification";
import './assets/tailwind.scss';
import vuetify from './plugins/vuetify'
import TextFieldComponent from "@/components/common/TextFieldComponent";
import AvatarComponent from "@/components/common/Avatar.vue";
import BadgeComponent from "@/components/common/Badge.vue";
import SelectBoxComponent from "@/components/common/SelectBoxComponent";
import PasswordFieldComponent from "@/components/common/PasswordFieldComponent";
import FooterParagraphTextComponent from "@/components/common/FooterParagraphTextComponent.vue";
import VueHtml2pdf from 'vue-html2pdf';
import moment from "moment";

ApiService.init();
Vue.use(VueHtml2pdf);
Vue.use(VuePageTransition)
Vue.filter('formatDate', function (value) {
  if (!value) return '';
  return moment(value).format('DD/MM/YYYY');
});
let filter = function (text, length, clamp) {
  clamp = clamp || " ...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter("truncate", filter);


Vue.mixin({
  methods: {
    getShortAbreviation(string) {
      const result = string.split(" ") || [];
      if (result.length >= 2) {
        return `${result[0].charAt(0)}${result[1].charAt(0)}`.toUpperCase();
      } else if (result.length === 1) {
        return `${result[0].charAt(0)}`.toUpperCase();
      } else return "";
    },

    generateSpecialColor(string) {
      const shortAbreviation = this.getShortAbreviation(string);
      if (shortAbreviation.length >= 2) {
        const firstLetter = shortAbreviation[0].toLowerCase();
        const secondLetter = shortAbreviation[1].toLowerCase();
        const seed = firstLetter.charCodeAt(0) * secondLetter.charCodeAt(0);
        const hue = seed % 360;
        const backgroundColor = `hsl(${hue}, 70%, 50%)`;
        return backgroundColor;
      }
      return `hsl(188, 100%, 50%)`
    },
  },
})

Vue.prototype.$app_url = serverConfig.appUrl;
Vue.prototype.$asset_url = serverConfig.assetsUrl;
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(snackbarPlugin, { store });
Vue.use(PerfectScrollbar);
Vue.use(RichTextEditor);
Vue.use(Notifications);
Vue.use(VueContentPlaceholders);
Vue.component("downloadExcel", JsonExcel);
Vue.component('AvatarComponent',AvatarComponent)
Vue.component('BadgeComponent',BadgeComponent)

if (!String.prototype.truncate) {
  Object.defineProperty(String.prototype, "truncate", {
    value: function (maxChars) { return this.length > maxChars 
      ? `${this.substring(0, maxChars)}...` : this; 
    }
  })
}

Vue.use(
  VueGtag,
  {
    config: { id: serverConfig.gaTracking },
  },
  router
);

Vue.component("footer-paragraph-text", FooterParagraphTextComponent)
Vue.component("PasswordFieldComponent", PasswordFieldComponent)
Vue.component("TextFieldComponent", TextFieldComponent)
Vue.component("SelectBoxComponent", SelectBoxComponent)

Vue.component("rich-text-editor", RichTextEditor)

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
